@import "bootstrap.config"

figure,
img
    max-width: 100%

.navbar-brand > img
    display: inline-block

body
    height: 100%
    background: url("../images/page_bg.png") no-repeat center top fixed
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover

.error-403
    background-color: $danger
    color: $white

    .container
        background: none
        background-color: transparent


.container,
.container-fluid
    background-color: rgba(255, 255, 255, 0.8)
    padding-top: 20px
    padding-bottom: 40px

.card,
.card-deck .card
    margin-bottom: 1rem

.card-deck-lg-3 .card
    min-width: 30%

    @include media-breakpoint-down(md)
        min-width: 45%


@include media-breakpoint-down(md)
    h1
        font-size: 1.75rem
    h2, h3, h4, h5, h6
        font-size: 1.25rem

.article-card
    .card-body
        padding-top: 0.75rem
        padding-bottom: 0.75rem

    .row > div
        padding-right: 8px
        padding-left: 8px

    &:hover .card-body
        background-color: theme-color-level("secondary", -11)

    &.border-success:hover .card-body
        background-color: theme-color-level("success", -11)

.barcode-scanner-viewport
    position: relative

    video, canvas
        max-width: 100%
        width: 100%

    .drawingBuffer
        position: absolute
        left: 0
        top: 0
